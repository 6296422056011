<template>
  <div class="container-fluid">
    <card class="no-border-card">
      <div class="row cards-grid">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_LOCATIONS)"
          :to="{ name: 'List Locations' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img src="../../../assets/img/EmplacementsIcon.svg" alt="" />
              </div>
              <small>{{ $t("COMMON.LOCATIONS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ORGANIZATIONS)"
          :to="{ name: 'List Organizations' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img src="../../../assets/img/OrganisationsIcon.svg" alt="" />
              </div>
              <small>{{ $t("COMMON.ORGANIZATIONS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_GROUP_ORGANIZATIONS)
          "
          :to="{ name: 'List Organizations Groups' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img
                  src="../../../assets/img/GroupedOrganisationsIcon.svg"
                  alt=""
                />
              </div>
              <small>{{ $t("COMMON.DEALERSGROUPS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_INVITATIONS)"
          :to="{ name: 'List Invitations' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img src="../../../assets/img/InvitationIcon.svg" alt="" />
              </div>
              <small>{{ $t("COMMON.INVITATIONS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLERS)"
          :to="{ name: 'List Resellers' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img src="../../../assets/img/RevendeursIcon.svg" alt="" />
              </div>
              <small>{{ $t("COMMON.RESELLERS") }}</small>
            </a>
          </div>
        </router-link>
        <!-- <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ORGANIZATIONS)"
          :to="{ name: 'List Events' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-building"></i>
              </span>
              <small>{{ $t("COMMON.EVENTS") }}</small>
            </a>
          </div>
        </router-link> -->

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_USERS)"
          :to="{ name: 'List Users' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img src="../../../assets/img/UtilisateursIcon.svg" alt="" />
              </div>
              <small>{{ $t("COMMON.USERS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ROLES)"
          :to="{ name: 'List Roles' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img src="../../../assets/img/RolesIcon.svg" alt="" />
              </div>
              <small>{{ $t("COMMON.ROLES") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_IMPORTS)"
          :to="{ name: 'List Imports' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <div class="img-menu">
                <img src="../../../assets/img/ImportsIcon.svg" alt="" />
              </div>
              <small>{{ $t("COMMON.IMPORTS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_LABELS)"
          :to="{ name: 'List Labels' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i
                  class="fa fa-tags"
                  aria-hidden="true"
                  style="font-size: 24px"
                ></i>
              </span>
              <small>{{ $t("LABELS.LABELS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CHARGES)"
          :to="{ name: 'List Charges' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <charge-icon width="35px" />
              </span>
              <small>{{ $t("CHARGES.CHARGES") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_EDIT_TEMPLATES_MAILS)"
          :to="{ name: 'List TemplatesMails' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-books"></i>
            </span>
            <small>{{ $t("COMMON.TEMPLATES_MAILS") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_CONTACT_FORMS)"
          :to="{ name: 'List ContactForms' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-email-83"></i>
            </span>
            <small>{{ $t("COMMON.CONTACT_FORMS") }}</small>
          </a>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CONTRACTS)"
          :to="{ name: 'List Contracts' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
              <i
                  class="fa fa-file"
                  aria-hidden="true"
                  style="font-size: 24px"
                ></i>
              </span>
              <small>{{ $t("CONTRACTS.CONTRACTS") }}</small>
            </a>
          </div>
        </router-link>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SIGNATURES)"
          :to="{ name: 'List Signatures' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
              <i class="fa fa-pencil" aria-hidden="true" style="font-size: 24px"></i>
              </span>
              <small>{{ $t("SIGNATURES.SIGNATURES") }}</small>
            </a>
          </div>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
import ChargeIcon from "@/components/Icons/ChargeIcon.vue";
export default {
  layout: "DashboardLayout",

  components: {
    ChargeIcon,
  },

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
